import React from 'react';
import styles from './Fallback.module.css';
import AB_LOGO_PNG from '../../assets/Logo/logo.png';

const Fallback = () => {
  return (
    <div className={styles.fallbackContainer}>
      <div className={styles.fallbackContainerImage}>
        <img src={AB_LOGO_PNG} className={styles.fallbackImage} />
      </div>
    </div>
  );
};

export default Fallback;
