import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import loadable from '@loadable/component';
import withTracker from '../Components/withTracker';
import Fallback from '../Components/Fallback';

const Home = loadable(() => import('./Home'));
const PrivacyPolicy = loadable(() => import('./PrivacyPolicy'));
const Footer = loadable(() => import('../Components/Footer'));

export default function RootPage() {
  return (
    <>
      <Navbar />
      <Suspense fallback={<Fallback />}>
        <Switch>
          <Route exact path="/" component={withTracker(Home)} />
          <Route
            exact
            path="/privacy_policy"
            component={withTracker(PrivacyPolicy)}
          />
        </Switch>
      </Suspense>
      <Footer />
    </>
  );
}
