import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import styles from './Navbar.module.css';

const NavbarLink = React.memo(({ href, linkText }) => {
  const [isActiveStyle, setActiveStyle] = useState(false);
  useEffect(() => {
    if (window.location.hash) {
      if (`/${window.location.hash}` === href) setActiveStyle(true);
      else setActiveStyle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash, href]);

  return (
    <a
      href={href}
      className={`navbarText ${
        isActiveStyle ? styles.activeText : styles.inactiveText
      }`}>
      {linkText}
    </a>
  );
});
NavbarLink.propTypes = {
  href: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};
export default withRouter(NavbarLink);
