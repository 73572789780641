import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-173444859-1');
}

const ROOT = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(ROOT, rootElement);
} else {
  render(ROOT, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
