import React, { useState, useCallback, useEffect } from 'react';
import Icon from '../Icon';
import DesktopLinks from './DesktopLinks';
import MobileLinks from './MobileLinks';
import { Logo } from './Logo';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [isMenuOpen, setMenu] = useState(true);
  useEffect(() => {
    let topPos;
    window.addEventListener('load', () => {
      if (window.location.hash) {
        topPos = document.getElementById(
          `${window.location.hash.split('#')[1]?.split('?')[0]}`
        )?.offsetTop;
        window.scrollTo(0, topPos + 10);
      }
    });
  }, []);
  const toggleNavBar = useCallback(() => {
    setMenu(!isMenuOpen);
  }, [isMenuOpen]);

  return (
    <div className={styles.parentContainer}>
      <div className={styles.childContainer}>
        <Logo />
        <div className={styles.mobileNavContainer}>
          <button
            type="button"
            onClick={toggleNavBar}
            data-testid="toggleNavbar">
            <Icon name="bars" size="lg" color="black" />
          </button>
        </div>
        <MobileLinks isMenuOpen={isMenuOpen} data-testid="mobileLink" />
        <DesktopLinks />
      </div>
    </div>
  );
};

export default Navbar;
