import React from 'react';
import LOGO from '../../assets/Logo/logo.png';
import styles from './Navbar.module.css';

export const Logo = () => {
  return (
    <div className={styles.logoContainer}>
      <div className={styles.abLogoImageContainer}>
        <img
          src={LOGO}
          alt="Alien Brains Logo"
          className={styles.abLogoImage}
        />
      </div>
      <span className={`footerButtonText ${styles.logoName}`}>
        ALIEN BRAINS
      </span>
    </div>
  );
};
