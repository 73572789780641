import React from 'react';
import PropTypes from 'prop-types';
import NavbarLink from './NavbarLink';
import styles from './Navbar.module.css';

const MobileLinks = React.memo(({ isMenuOpen }) => {
  return (
    <div
      className={`${styles.mobileLinkContainer} ${
        isMenuOpen ? styles.collapseNavbarMenu : styles.showNavbarMenu
      }`}>
      <NavbarLink href="/#home" linkText="HOME" />
      <NavbarLink href="/#mission" linkText="MISSION" />
      <NavbarLink href="/#aboutus" linkText="ABOUT US" />
      <NavbarLink href="/#joinwithus" linkText="WORK WITH US" />
    </div>
  );
});
export default MobileLinks;
MobileLinks.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired
};
