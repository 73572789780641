import React from 'react';
import PropTypes from 'prop-types';
import styles from './Icon.module.css';

const Icon = ({ name, size, color, isBrand, href, disabled = false }) => {
  if (name)
    return (
      <a
        className={styles.iconContainer}
        style={disabled ? { cursor: 'not-allowed' } : {}}
        href={href}
        rel="noopener noreferrer"
        target="_blank">
        <i
          className={`${isBrand ? `fab` : `fas`} fa-${name} fa-${size} icon`}
          style={color ? { color } : disabled ? { color: 'grey' } : {}}
          data-testid="icon"
        />
      </a>
    );
  return null;
};

export default Icon;

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  isBrand: PropTypes.bool
};

Icon.defaultProps = {
  size: 'lg',
  color: '',
  isBrand: false
};
