import React from 'react';
import NavbarLink from './NavbarLink';
import styles from './Navbar.module.css';

const DesktopLinks = () => {
  return (
    <div className={styles.desktopLinkContainer} data-testid="desktopLink">
      <NavbarLink href="/#home" linkText="HOME" />
      <NavbarLink href="/#mission" linkText="MISSION" />
      <NavbarLink href="/#aboutus" linkText="ABOUT US" />
      <NavbarLink href="/#joinwithus" linkText="WORK WITH US" />
    </div>
  );
};
export default DesktopLinks;
